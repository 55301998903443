<template>
  <form-notification />
</template>
<script>
import FormNotification from '@/components/FormComponents/Notification'

export default {
  components: {
    FormNotification,
  },
}
</script>
